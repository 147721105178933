import { useQuery } from 'react-query';

const useVendors = () =>
  useQuery<{ id: string; name: string }[]>('vendors', async () => {
    const res = await fetch('api/vendors');
    if (!res.ok) {
      throw new Error('api fetch error');
    }
    return res.json();
  });

export default useVendors;
