import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import Image from 'next/image';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import searchIcon from '../../../public/Icon/shop/search.png';
import 'react-datepicker/dist/react-datepicker.css';
import UplText from '../../atoms/UplText/UplText';
import useVendors from '../../../hooks/top/useVendors';
import useDrawerContext from '../../../hooks/top/useDrawerContext';
import kanaToHira from '../../../modules/util/product/kanaToHira';

registerLocale('ja', ja);

const UplSearchBox: React.FC<{
  placeholder?: string;
  onClick?: (searchWord: string) => void;
  dataCy?: string;
}> = ({ placeholder = '検索ワード', onClick, dataCy }) => {
  const [searchWord, setSearchWord] = useState('');
  const router = useRouter();

  useEffect(() => {
    setSearchWord('');
  }, [router.asPath]);

  return (
    <div className='flex items-center md:justify-center'>
      <input
        type='text'
        className='h-10 w-full rounded-l rounded-r-none border border-gray-200 p-2 text-base outline-none'
        placeholder={placeholder}
        value={searchWord || ''}
        onChange={(e) => {
          setSearchWord(e.target.value);
        }}
        data-cy={`search-text-box-${dataCy}`}
      />
      <div
        role='none'
        className='h-10 cursor-pointer rounded-r bg-dark-gray p-2 text-center hover:bg-dark-gray-300'
        onClick={() => {
          if (onClick) {
            onClick(searchWord);
          }
        }}
        data-cy={`search-button-${dataCy}`}
      >
        <Image
          src={searchIcon}
          width={20}
          height={20}
          alt='検索'
          data-cy='search-icon-image'
        />
      </div>
    </div>
  );
};

export const UplVendorSearchBox: React.FC<{
  onClick?: (searchWord: string) => void;
  dataCy?: string;
}> = ({ onClick, dataCy }) => {
  const [searchWord, setSearchWord] = useState('');
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const { data } = useVendors();
  const { isDrawerOpen } = useDrawerContext();

  const hiraSearchWord = useMemo(
    () => kanaToHira(searchWord).toUpperCase(),
    [searchWord],
  );

  const containWord = useCallback(
    (name: string) =>
      kanaToHira(name).toUpperCase().indexOf(hiraSearchWord) !== -1,
    [hiraSearchWord],
  );

  const existsVendor = useMemo(
    () => data?.some((v) => containWord(v.name)),
    [containWord, data],
  );

  const handleSelect = (name: string) => {
    if (onClick) {
      onClick(name);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    setSearchWord('');
    setIsOpen(false);
  }, [router.asPath, isDrawerOpen]);

  return (
    <>
      <div className='flex items-center md:justify-center'>
        <div className='relative z-10 w-full'>
          <input
            type='text'
            className='h-10 w-full rounded-l rounded-r-none border border-gray-200 p-2 text-base outline-none'
            placeholder='メーカー名を入力してください'
            value={searchWord || ''}
            onChange={(e) => {
              setSearchWord(e.target.value);
            }}
            onFocus={() => setIsOpen(true)}
            data-cy={`vendor-search-text-box-${dataCy}`}
          />
          {isOpen && (
            <ul
              className='absolute max-h-64 w-full overflow-y-auto rounded rounded-t-none bg-white shadow-lg'
              data-cy={`vendor-search-list-${dataCy}`}
            >
              {data
                ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                .map((v: { id: string; name: string }) => {
                  if (containWord(v.name)) {
                    return (
                      <li
                        className='mx-2 list-none border-b-2 border-white-gray p-2 last:border-none hover:cursor-pointer hover:bg-white-gray'
                        key={v.name}
                        onClick={() => handleSelect(v.name)}
                        aria-hidden
                      >
                        <UplText variant='subtext'>{v.name}</UplText>
                      </li>
                    );
                  }
                  return null;
                })}
              {!existsVendor && (
                <li className='mx-2 list-none border-white-gray p-2'>
                  <UplText variant='subtext' color='description'>
                    該当なし
                  </UplText>
                </li>
              )}
            </ul>
          )}
        </div>
        <div
          role='none'
          className='h-10 cursor-pointer rounded-r bg-dark-gray p-2 text-center hover:bg-dark-gray-300'
          onClick={() => {
            if (onClick) {
              onClick(searchWord);
            }
          }}
          data-cy={`vendor-search-button-${dataCy}`}
        >
          <Image
            src={searchIcon}
            width={20}
            height={20}
            alt='検索'
            data-cy={`vendor-search-icon-image-${dataCy}`}
          />
        </div>
      </div>
      {isOpen && (
        <div
          className='fixed left-0 top-0 z-0 h-screen w-screen'
          aria-hidden
          onClick={() => setIsOpen(false)}
          data-cy='hidden-screen'
        />
      )}
    </>
  );
};

const PortalCustomInput: React.FC<{
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string;
}> = ({ value, onChange, onClick }) => (
    <input
      className='h-10 w-full rounded-l rounded-r-none border border-gray-200 p-2 text-sm outline-none'
      placeholder='日付を選択'
      type='text'
      value={value}
      onChange={onChange}
      onClick={onClick}
      readOnly
    />
  );
export const UplDateSearchBox: React.FC<{
  onClick?: (date: Date | null) => void;
  dataCy?: string;
}> = ({ onClick, dataCy }) => {
  const [date, setDate] = useState<Date | null>(null);
  const router = useRouter();

  useEffect(() => {
    setDate(null);
  }, [router.asPath]);

  return (
    <div className='flex items-center md:justify-center'>
      <div
        className='hidden w-full lg:block'
        data-cy={`date-search-text-box-${dataCy}`}
      >
        <DatePicker
          locale='ja'
          dateFormat='yyyy/MM/dd'
          selected={date}
          onChange={(d) => setDate(d)}
          className='h-10 w-full rounded-l border border-gray-200 p-2 text-sm outline-none'
          placeholderText='日付を選択'
          popperClassName='!z-30'
          isClearable
          wrapperClassName='w-full'
        />
      </div>
      <div
        className='hidden w-full sm:block md:block'
        data-cy={`date-text-box-${dataCy}`}
      >
        <DatePicker
          locale='ja'
          dateFormat='yyyy/MM/dd'
          selected={date}
          onChange={(d) => setDate(d)}
          popperClassName='!z-30'
          withPortal
          disabledKeyboardNavigation
          isClearable
          customInput={<PortalCustomInput />}
          wrapperClassName='w-full'
        />
      </div>
      <div
        role='none'
        className='h-10 cursor-pointer rounded-r bg-dark-gray p-2 text-center hover:bg-dark-gray-300'
        onClick={() => {
          if (onClick) {
            onClick(date);
          }
        }}
        data-cy={`date-search-button-${dataCy}`}
      >
        <Image src={searchIcon} width={20} height={20} alt='検索' />
      </div>
    </div>
  );
};

export default UplSearchBox;
