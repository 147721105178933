export const CATEGORY = [
  {
    label: 'カテゴリA',
    id: 0,
  },
  {
    label: 'カテゴリB',
    id: 1,
  },
  {
    label: 'カテゴリC',
    id: 2,
  },
  {
    label: 'カテゴリD',
    id: 3,
  },
  {
    label: 'カテゴリE',
    id: 4,
  },
  {
    label: 'カテゴリF',
    id: 5,
  },
];

export const CATEGORIES = [
  { value: '', label: '全てのカテゴリ' },
  { value: 'カテゴリA', label: 'カテゴリA' },
  { value: 'カテゴリB', label: 'カテゴリB' },
  { value: 'カテゴリC', label: 'カテゴリC' },
  { value: 'カテゴリD', label: 'カテゴリD' },
  { value: 'カテゴリE', label: 'カテゴリE' },
  { value: 'カテゴリF', label: 'カテゴリF' },
];

export interface CampaignCollection {
  value: string;
  label: string;
  image: string;
  description?: string;
  color: string;
}
export interface CampaignProps extends CampaignCollection {
  imgSrc: string;
  imgAlt: string;
  searchUrl?: string;
}

export const CAMPAIGN_CATEGORY: CampaignCollection[] = [
  {
    value: '0000-00-00オススメ',
    label: 'オススメ',
    image: 'recommend',
    color: 'theme',
  },
  {
    value: '0001-00-00アウトレット',
    label: 'アウトレット',
    image: 'sale',
    color: 'red',
  },
  {
    value: '0002-00-00one-order',
    label: 'ONE ORDER',
    image: 'oneorder',
    description: '合板1枚からお届け',
    color: 'olieve-100',
  },
];

// 事前に商品登録したいコレクション
// 公開時にオブジェクト削除
export const PRERELEASE_CATEGORY: { title: string }[] = [
  {
    title: '0003-00-00中古品',
  },
  {
    title: '0003-01-00未使用品',
  },
  {
    title: '0003-02-00使用品',
  },
];
