import React, { FC, useState } from 'react';
import UplText from '../../atoms/UplText/UplText';

interface UplSortSelectProps {
  list: {
    value: string;
    name: string;
  }[];
  value?: string;
  onChange?: (value: string) => void;
}

const UplSortSelect: FC<UplSortSelectProps> = ({
  list,
  value = '-',
  onChange = () => {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div
        data-testid='UplSortSelect'
        className={`${isOpen && 'z-20'} relative h-10 w-72 bg-white`}
      >
        <div className='rounded border border-gray bg-white'>
          <div
            className='cursor-pointer p-2'
            onClick={() => setIsOpen(!isOpen)}
            role='none'
            data-cy='sort-select'
          >
            <div
              aria-hidden='true'
              className='flex items-center justify-between text-sm'
              data-testid='sortSelect'
            >
              <div className='whitespace-nowrap'>
                <span className='w-10'>
                  <UplText variant='subtext' color='description'>
                    並び替え
                  </UplText>
                </span>
                <span className='ml-2'>
                  <UplText variant='text' bold='semibold'>
                    {list.find((l) => l.value === value)?.name}
                  </UplText>
                </span>
              </div>
              <span className='allows-under-black' />
            </div>
          </div>
          {isOpen && (
            <ul
              className='top-full rounded-b bg-white'
              data-cy='sort-select-list'
            >
              {list.map((l) => (
                <li
                  key={l.value}
                  className={`mx-2 my-1 cursor-pointer rounded py-2 pl-4 ${
                    l.value === value ? 'bg-theme' : 'hover:bg-white-gray'
                  }`}
                  onClick={() => {
                    onChange(l.value);
                    setIsOpen(false);
                  }}
                  aria-hidden='true'
                >
                  <UplText
                    variant='subtext'
                    color={l.value === value ? 'white' : 'primary'}
                  >
                    {l.name}
                  </UplText>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {isOpen && (
        <div
          className='fixed left-0 top-0 z-10 h-screen w-screen'
          aria-hidden
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default UplSortSelect;
