const MESSAGE = {
  /** 処理を実行できません。時間を置いて再度実行してください。 */
  'error.sys.001': '処理を実行できません。時間を置いて再度実行してください。',
  /** 登録に失敗しました。 */
  'error.sys.002': '登録に失敗しました。',
  'error.sys.003': '適切な権限がないため、アクセスが拒否されました',
  'error.sys.004': 'メールの送信に失敗しました。',
  /** 1回に注文できる商品点数は20点までです。 */
  'error.api.001': '1回に注文できる商品点数は20点までです。',
  /** 商品1点につき、注文できる個数は9999個までです。 */
  'error.api.002': '商品1点につき、注文できる個数は9999個までです。',
  /** 指定された郵便番号は配送不可エリアです */
  'error.api.003': '指定された郵便番号は配送不可エリアです',
  /** 不明なエラーが発生しました！時間を置いてお試しください。 */
  'error.front.001': '不明なエラーが発生しました！時間を置いてお試しください。',
};

export default MESSAGE;
