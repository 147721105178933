import {
  CAMPAIGN_CATEGORY,
  CampaignCollection,
  CampaignProps,
  PRERELEASE_CATEGORY,
} from '../../constants/CategoryConstants';
import { Collection } from '../../types/shopify/products/Collection.type';

export type Relation = {
  parent: Collection;
  children: Relation[];
};

const isCampaign = (
  value: string,
  key: string,
  campaign: CampaignCollection,
) => {
  if (key === 'label') {
    return campaign[key] === value;
  }
  const categoryRegex = /(^[0-9]{4}-[0-9]{2}-[0-9]{2}).*$/;
  const valueId = value.replace(categoryRegex, (_, $1) => $1);
  const categoryId = campaign['value'].replace(categoryRegex, (_, $1) => $1);
  return valueId === categoryId;
};

export default class CollectionsModel {
  public relations: Relation[] = [];

  private data: Collection[] = [];

  constructor(collections?: Collection[]) {
    this.data = collections || [];

    const grandParents: Collection[] = [];

    const parents: Collection[] = [];

    const children: Collection[] = [];

    // 昇順
    collections?.sort(
      (a, b) =>
        Number(a.title?.replace(/\D/g, '')) -
        Number(b.title?.replace(/\D/g, '')),
    );

    collections?.forEach((edge) => {
      const title = edge.title || '';
      if (CollectionsModel.isPrerelease(title)) {
        // 画面に表示したくないカテゴリーはコレクションに含めない
      } else if (CollectionsModel.isGrandparent(title)) {
        grandParents.push(edge);
      } else if (CollectionsModel.isParent(title)) {
        parents.push(edge);
      } else {
        children.push(edge);
      }
    });

    grandParents.forEach((gp) => {
      const relation: Relation = {
        parent: gp,
        children: [],
      };
      const gpCode = gp.title?.split('-') || [];
      const grandParentregex = new RegExp(`^${gpCode[0]}-*`);
      parents.forEach((p) => {
        const pCode = p.title?.split('-') || [undefined, undefined];
        if (grandParentregex.test(p.title || '')) {
          const parentRelation: Relation = {
            parent: p,
            children: [],
          };
          relation.children.push(parentRelation);
          const parentRegex = new RegExp(`^${pCode[0]}-${pCode[1]}-*`);
          children.forEach((c) => {
            const childRelation: Relation = {
              parent: c,
              children: [],
            };
            if (parentRegex.test(c.title || '')) {
              parentRelation.children.push(childRelation);
            }
          });
        }
      });

      this.relations.push(relation);
    });
  }

  private static isGrandparent(title: string) {
    return (
      title.indexOf('-00-00') >= 0 ||
      title.indexOf('9999-99-99') >= 0 ||
      /^7777-[0-9]{2}-[0-9]{2}/.test(title)
    );
  }

  private static isParent(title: string) {
    return title.indexOf('-00-00') < 0 && title.indexOf('-00') >= 0;
  }

  private static isPrerelease(title: string) {
    return !!PRERELEASE_CATEGORY.find(
      (prerelease) => prerelease.title === title,
    );
  }

  static getDisplayTitle(title?: string) {
    return title?.replace(/^[0-9]{4}-[0-9]{2}-[0-9]{2}/g, '');
  }

  static getDisplayTitles(relations?: Relation[]) {
    const titles: (string | undefined)[] = [];
    relations?.forEach((r) => {
      const replaced = CollectionsModel.getDisplayTitle(r.parent?.title);
      titles.push(replaced);
    });

    return titles;
  }

  getTitleByHandle(handle?: string) {
    if (!handle) {
      return undefined;
    }
    return this.data.find((d) => d.handle === handle)?.title;
  }

  getHandleByTitle(title?: string) {
    if (!title) {
      return undefined;
    }
    return this.data.find((d) => d.title === title)?.handle;
  }

  /**
   *
   * @param productType
   * @returns Relation | undefined
   */
  getRelationByProductType(productType: string) {
    const targetIndex = this.relations.findIndex(({ parent }) => {
      const parentCategoryId = parent.handle?.substring(0, 4);
      const productTypeId = productType.substring(0, 4);
      return parentCategoryId === productTypeId;
    });
    return this.relations[targetIndex];
  }

  static isCampaignCollection(value: string, key: 'label' | 'value' = 'label') {
    return !!CAMPAIGN_CATEGORY.find((campaign) =>
      isCampaign(value, key, campaign),
    );
  }

  /**
   * @param value - 検索する値
   * @param key - ラベル名かコレクション名どちらで検索するか指定する
   * @returns CampaignCollection | undefined
   */
  static getCampaignCollectionProps(
    value: string,
    key: 'label' | 'value' = 'label',
  ): CampaignProps | undefined {
    const campaignCollection = CAMPAIGN_CATEGORY.find((campaign) =>
      isCampaign(value, key, campaign),
    );
    return !!campaignCollection
      ? {
          ...campaignCollection,
          imgSrc: `/Icon/shop/${campaignCollection.image}.png`,
          imgAlt: `${campaignCollection.image}-icon`,
          searchUrl: key === 'value' ? `/?c=${value}` : undefined,
        }
      : undefined;
  }
}
