import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const UplWrapper: React.FC<Props> = ({ children }) => (
  <div className='flex min-h-screen flex-col'>{children}</div>
);

export default UplWrapper;
