import React, { FC } from 'react';
import { MdCancel } from 'react-icons/md';
import CollectionsModel from '../../../models/top/CollectionsModel';
import UplText from '../../atoms/UplText/UplText';

interface SearchBadgesProps {
  searchWords: { [key: string]: string | undefined };
  onChange?: (searchWords: { [key: string]: string | undefined }) => void;
  onClear?: () => void;
  count?: number;
}

const Badge: FC<{ label: string; children: React.ReactNode }> = ({
  label,
  children,
}) => (
  <div className='flex flex-col' data-cy='badge-container'>
    <UplText variant='description'>{label} </UplText>
    <UplText variant='text' bold='semibold'>
      {children}
    </UplText>
  </div>
);

const SearchWordBadge: FC<{
  entries: [string, string | undefined];
  onClick: (value?: string) => void;
}> = ({ entries, onClick }) => {
  const key = entries[0];
  let value = (
    <UplText variant='h2' bold='semibold'>
      {entries[1]}
    </UplText>
  );

  switch (key) {
    case 'sw':
      value = <Badge label='検索ワード'>{entries[1]}</Badge>;
      break;
    case 't':
      value = <Badge label='商品名'>{entries[1]}</Badge>;
      break;
    case 'mt':
      value = <Badge label='見積名'>{entries[1]}</Badge>;
      break;
    case 'mno':
      value = <Badge label='見積番号'>{entries[1]}</Badge>;
      break;
    case 'date':
      value = <Badge label='日付'>{entries[1]}</Badge>;
      break;
    case 'v':
      value = <Badge label='メーカー'>{entries[1]}</Badge>;
      break;
    case 'c':
      value = (
        <Badge label='カテゴリ'>
          {CollectionsModel.getDisplayTitle(entries[1])}
        </Badge>
      );
      break;
    case 'min':
      value = (
        <Badge label='下限価格'>{`¥${Number(entries[1]).toLocaleString(
          'ja',
        )}`}</Badge>
      );
      break;
    case 'max':
      value = (
        <Badge label='上限価格'>{`¥${Number(entries[1]).toLocaleString(
          'ja',
        )}`}</Badge>
      );
      break;
    default:
      break;
  }

  return (
    <div>
      <div className='mx-1 mb-1 flex w-fit items-center justify-between rounded bg-theme-100 p-2'>
        {value}
        <div
          className='ml-2 cursor-pointer text-2xl text-theme-300 hover:text-theme'
          onClick={() => onClick(entries[1])}
          aria-hidden
          data-cy={`cancel-icon-${key}`}
        >
          <MdCancel />
        </div>
      </div>
    </div>
  );
};

const SearchBadges: FC<SearchBadgesProps> = ({
  searchWords,
  onChange = () => {},
  onClear = () => {},
  count = 0,
}) => (
  <div>
    <div>
      <span onClick={onClear} aria-hidden>
        <UplText variant='subtext' color='link' link dataCy='search-clear-link'>
          検索条件を全てクリア
        </UplText>
      </span>
    </div>
    <div className='mt-1 flex flex-wrap items-center sm:mt-3 md:mt-3'>
      {Object.entries(searchWords)
        .map((searchWord) => {
          const [key, value] = searchWord;
          let valueArray = [value];
          if (key === 'sw') {
            valueArray = value?.split(/\s/g) || [];
          }
          return (
            valueArray[0] &&
            valueArray.map((v, i) => (
              <SearchWordBadge
                // eslint-disable-next-line react/no-array-index-key
                key={`${key}-${i}`}
                onClick={(cv) => {
                  const newWords = { ...searchWords };
                  if (key === 'sw') {
                    const target = newWords[key];
                    const splitTarget = (target?.split(/\s/g) || []).filter(
                      (t) => t !== cv,
                    );
                    if (splitTarget.length === 0) {
                      delete newWords[key];
                    } else {
                      newWords[key] = splitTarget.join(' ');
                    }
                  } else {
                    delete newWords[key];
                  }
                  onChange(newWords);
                }}
                entries={[key, v]}
              />
            ))
          );
        })
        .flat()}
      <div className='ml-1'>
        <UplText variant='text'>の検索結果</UplText>
        <label className='ml-2'>
          <UplText
            variant='h1'
            bold='semibold'
            color='link'
            dataCy='search-result-number'
          >
            {count || 0}
          </UplText>
          <UplText variant='text'> 件</UplText>
        </label>
      </div>
    </div>
  </div>
);

export default SearchBadges;
