import React, { FC, useState } from 'react';
import UplText from '../UplText/UplText';

interface UplTooltipProps {
  content: string;
  children?: React.ReactNode;
}

const UplTooltip: FC<UplTooltipProps> = ({ children, content }) => {
  const [show, setShow] = useState(false);

  const handleHover = () => {
    setShow(true);
  };

  const handleBlur = () => {
    setShow(false);
  };

  // ツールチップの本体
  const tipBody =
    'absolute z-10 py-2 px-4 text-center whitespace-nowrap bg-dark-gray rounded border-dark-gray sm:hidden md:hidden';

  // ツールチップの表示位置
  const location = 'bottom-[calc(100%+15px)] left-1/2 -translate-x-1/2';

  // 吹き出しの位置
  const allow =
    'after:absolute after:bottom-[-15px] after:left-1/2 after:content-[""] after:-translate-x-1/2 after:tip-allows-under-gray';

  return (
    <div className='relative inline-block'>
      <div onMouseLeave={handleBlur} onMouseEnter={handleHover} aria-hidden>
        {children}
      </div>
      {show && (
        <div
          className={[tipBody, location, allow].join(' ')}
          data-cy='cart-tool-tip'
        >
          <UplText variant='subtext' color='white'>
            {content}
          </UplText>
        </div>
      )}
    </div>
  );
};

export default UplTooltip;
