import { ReactElement } from 'react';

type Props = {
  children: [ReactElement, ReactElement];
  margin?: string;
};

const UplTwoColumnContainer: React.FC<Props> = ({
  children,
  margin = 'mx-auto lg:mt-5 md:mt-0',
}) => {
  const style = `${margin} py-2 px-2 pb-20 lg:grid md:grid-cols-1 md:px-10 sm:px-4 lg:grid-cols-4 w-full max-w-[1400px] mx-auto`;
  return (
    <main className={style}>
      <div className='pr-4 sm:hidden md:hidden lg:col-span-1'>
        {children[0]}
      </div>
      <div className='sm:col-span-1 md:col-span-1 lg:col-span-3'>
        {children[1]}
      </div>
    </main>
  );
};

export default UplTwoColumnContainer;
